import classes from '../../../css/styles.module.scss';
import { Link } from 'react-router-dom';
import Footer from '../../../Footer/Footer';
import { Helmet } from 'react-helmet';

const Proyectos = () => {
    return (
        <div>
             <Helmet><title>Backend Helpers Limited | success-stories </title></Helmet>
            <main className={classes['main-content']}>
    	<section id="projects" className={`${classes['container-s']} ${classes.projects}`}>
    		 <div  className={`${classes['project-card-border']} ${classes['mr-2']}`}>
                <div className={classes['project-card']}>
                    <h2 className={classes['project-card-title']}>AWS Infrastructure for the Americas Free Trade Zone association</h2>
                    <button type="button" className={classes['project-btn']}><Link to="./aws-azfa" >See more </Link></button>
                </div>
             </div>
             <div  className={classes['project-card-border']}>
                <div className={classes['project-card']}>
                    <h2 className={classes['project-card-title']}>EcoRolling <br /> Serverless</h2>
                    <button type="button" className={classes['project-btn']}> <Link to="./ecorolling"> See more </Link></button>
                </div>
             </div>
    	</section>
    </main>
        <Footer/>
        </div>
    );
};

export default Proyectos;