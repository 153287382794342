import logo from './img/logo.png';
import classes from '../css/styles.module.scss';
import {useTranslation, withTranslation,  Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

function Header() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

      // Obtén el idioma actual

  const currentLanguage = i18n.language;


    function toogle() {
      let x = document.getElementById("myTopnav");
      if (x.className === classes.topnav) {
        x.className += ` ${classes.responsive}`;
      } else {
        x.className = classes.topnav;
      }
    }

    return (
        <header>
            <div className={classes['menu-flex']}>
                {/* logo */}
                <div>
                    <Link to={currentLanguage === 'es' ? "/es" : "/en"}>
                        <img src={logo} alt="Backend Helpers logo" className={classes['img-logo']} />
                    </Link>
                </div>
                {/* navigation links */}
                <div className={classes['menu-flex']}>
                    {/* navigation large & medium devices */}
                    <nav className={classes.topnav} id="myTopnav">

                        <Link to={currentLanguage === 'es' ? "/es/historias-exito" : "/en/success-stories"} >
                         <Trans i18nKey="menu.success-stories"></Trans></Link>

                        <Link to="https://blog.backendhelpers.co/"  target="_blank">blog</Link>

                        <Link className={classes['contact-link']} to={currentLanguage === 'es' ? "/es/contacto" : "/en/contact"}><Trans i18nKey="menu.get-in-touch"></Trans></Link>

                        <a  className={classes.icon} onClick={toogle}>
                            <i className="fa fa-bars"></i>
                        </a>
                    </nav>
                    {/* navigation large & medium devices */}
                </div>
                {/* navigation links */}
            </div>

       

        </header>
    );
}

export default Header;
