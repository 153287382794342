import azfa_arquitecture from '../../../img/AZFA/azfa_arquitecture.png'
import azfa_cluster from '../../../img/AZFA/azfa_cluster.png'
import classes from '../../../css/styles.module.scss';
import Footer from '../../../Footer/FooterE';
import { Helmet } from 'react-helmet';

const Azfa = () => {
    return (
        <div>
            <Helmet><title>Backend Helpers Limited | azfa </title></Helmet>
            <main className={classes['main-content']}>
            <section id="" className={classes['project-content']}>
                <h1 className={classes['project-title']}>Infraestructura AWS <br /> para la Asociación Zonas Francas de <br />  las Américas</h1>
                <p className={classes['mb-1']}> Nuestra empresa es responsable de <span className={classes.negrilla}>operar un sitio web de alto perfil
                 </span> en nombre de la Asociación de Libre Comercio de las Américas: una  <span className={classes.negrilla}> organización multilateral </span> que agrupa regiones de libre comercio en toda América. 
                <a href="https://www.asociacionzonasfrancas.org/"  target="_blank" rel="noreferrer"> asociacionzonasfrancas.org </a> </p>
                <hr className={classes['bh-divider']}/> 
                <h2 className={classes['project-subtitle']}> Arquitectura</h2>
                <p>La siguiente imagen describe la implementacion que se a realizado: </p>
                <img src={azfa_arquitecture} alt="AZFA architecture" className={classes['img-tech']}/>
                <div className={classes['container-azfa']}>
                <p>Usamos <span className={classes.negrilla}>Hashicorp terraform</span>  para crear una infraestructura como pila de código que incluye los siguientes componentes: </p>
                <ul className={classes.lyst}>
                    <li>VPC personalizado que aísla todos los recursos</li>
                    <li>Firewall WAF para proteger el sitio web contra ataques comunes</li>
                    <li>Balanceador de carga que distribuye la carga de trabajo entre diferentes servidores</li>
                    <li>Distribución de Cloud Front respaldada por un bucket s3 para gestionar los activos estáticos</li>
                    <li>Instancias EC2 para ejecutar aplicaciones de servidor web en Python</li>
                    <li>Base de datos RDS para postgres SQL con réplica de lectura habilitada</li>
                </ul>
                </div>
                <hr className={classes['bh-divider']}/> 
                <h2 className={classes['project-subtitle2']}> PRÁCTICAS CONTINUAS DE INTEGRACIÓN, AUTOMATIZACIÓN Y DEVOPS</h2>
                <p>Seguimos las <span className={classes.negrilla}>mejores prácticas de Devops</span> para la<span className={classes.negrilla}> integración y las implementaciones continuas de múltiples cuentas.</span> </p>
                <img src={azfa_cluster} alt="AZFA website" className={`${classes['img-tech']} ${classes['mb-1']}`}/>
            </section>
            </main>
            <Footer/>
        </div>
    );
};

export default Azfa;