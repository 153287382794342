import React from 'react';
import classes from '../../../css/styles.module.scss';
import Footer from '../../../Footer/FooterE';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { Helmet } from 'react-helmet';
const Us_e = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

   
    return (
        <div>
        <Helmet>
        <title>Backend Helpers Limited | nosotros </title>
        </Helmet>
         <div className={classes.languageButtons}>
        <Link to="/en"> <button type="button" onClick={() => changeLanguage('en')} className={classes.button_lang}> en</button> </Link>   
        </div>
            <main className={classes['main-content']}>
            <section id="index" className={`${classes.container} ${classes.index}`}>
                <div className={classes['contact-left']}>
                    <h1 className={classes['rw-sentence']}>
                        Automatizamos tu <br />
                        <span className={classes['bg-title']}>infraestructura</span><br />
                         AWS - AZURE
                    </h1>
                </div>
                <div className={`${classes['col-6']} ${classes['col-s-12']} ${classes['padding-1']} `}>
                    <h2 className={classes['text-left']}>QUIENES SOMOS  </h2>
                    <p className={classes.quien}>Somos una <span className={classes.negrilla}>fábrica de software</span>, nos apoyamos en
                        <span className={classes.negrilla}> automatización</span> para implementar soluciones en la nube.
                        Nuestro enfoque se orienta a la <span className={classes.negrilla}>seguridad</span>, <span className={classes.negrilla}>confiabilidad</span> y <span className={classes.negrilla}>optimización de costos</span>  </p>
                </div> 
                
            </section>
            </main>
            <Footer/>
        </div>
        
    );
};


export default Us_e;