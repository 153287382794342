import classes from '../../../css/styles.module.scss';
import { Link } from 'react-router-dom';
import Footer from '../../../Footer/Footer';
import { Helmet } from 'react-helmet';


const Proyectos = () => {
    return (
        <div>
             <Helmet><title>Backend Helpers Limited | success-stories </title></Helmet>
            <main className={classes['main-content']}>
    	<section id="projects" className={`${classes['container-s']} ${classes.projects}`}>
    		 <div  className={`${classes['project-card-border']} ${classes['mr-2']}`}>
                <div className={classes['project-card']}>
                    <h2 className={classes['project-card-title']}>Infraestructura AWS <br /> para la Asociación Zonas Francas <br /> de las Américas</h2>
                    <button type="button" className={classes['project-btn']}><Link to="./aws-azfa" >Ver mas </Link></button>
                </div>
             </div>
             <div  className={classes['project-card-border']}>
                <div className={classes['project-card']}>
                    <h2 className={classes['project-card-title']}>EcoRolling <br /> Arquitectura AWS serverless</h2>
                    <button type="button" className={classes['project-btn']}> <Link to="./ecorolling"> Ver mas </Link></button>
                </div>
             </div>
    	</section>
    </main>
        <Footer/>
        </div>
    );
};

export default Proyectos;