import eco_arquitecture from '../../../img/Ecorolling/eco_arquitecture.png'
import eco_aws from '../../../img/Ecorolling/eco_aws.png'
import classes from '../../../css/styles.module.scss';
import Footer from '../../../Footer/FooterE';
import { Helmet } from 'react-helmet';



const Ecorolling = () => {
        return (
            <div>
                <Helmet><title>Backend Helpers Limited | azfa </title></Helmet>
                <main className={classes['main-content']}>
                <section id="" className={classes['project-content']}>
                    <h1 className={classes['project-title']}>EcoRolling <br />Arquitectura AWS serverless</h1>
                    <p className={classes['mb-1']}> Diseñamos y construimos una <span className={classes.negrilla}>arquitectura sin servidor</span> para una tienda en línea con un fuerte enfoque en la  <span className={classes.negrilla}>optimización de costos  </span> y  <span className={classes.negrilla}>creación rápida de prototipos.</span>  La solución incluye una combinación de componentes <span className={classes.negrilla}> WAF, Api Gateway, Lambda Functions</span> y <span className={classes.negrilla}>DynamoDB</span> diseñados para funcionar en un <span className={classes.negrilla}>entorno de múltiples cuentas.</span> La infraestructura fue creada utilizando <span className={classes.negrilla}>Terraform.</span>  </p>
                    <hr className={classes['bh-divider']}/> 
                    <h2 className={classes['project-subtitle']}> Arquitectura</h2>
                    <p>La siguiente imagen describe la implementacion que se a realizado: </p>
                    <img src={eco_arquitecture} alt="AZFA architecture" className={classes['img-tech']}/>
                    <hr className={classes['bh-divider']}/> 
                    <h2 className={classes['project-subtitle']}>Tabla DynamoDB Y estructura (MVC)</h2>
                    <p>Proporcionamos un <span className={classes.negrilla}>marco de Python</span> para mapear estructuras de datos orientadas a objetos al formato  <span className={classes.negrilla}>NOSQL DynamoDB </span> seguimos las <span className={classes.negrilla}>prácticas de Devops</span> para la integracion y las  <span className={classes.negrilla}>implementaciones continuas de múltiples cuentas.</span> </p>
                    <img src={eco_aws} alt="AZFA website" className={`${classes['img-tech2']} ${classes['mb-1']}`}/>
                </section>
                </main>
                <Footer/>
            </div>
    );
;}

export default Ecorolling;