import eco_arquitecture from '../../../img/Ecorolling/eco_arquitecture.png'
import eco_aws from '../../../img/Ecorolling/eco_aws.png'
import classes from '../../../css/styles.module.scss';
import Footer from '../../../Footer/Footer';
import { Helmet } from 'react-helmet';



const Ecorolling = () => {
    return (
        <div>
            <Helmet><title>Backend Helpers Limited | azfa </title></Helmet>
            <main className={classes['main-content']}>
            <section id="" className={classes['project-content']}>
                <h1 className={classes['project-title']}>EcoRolling Serverless</h1>
                <p className={classes['mb-1']}> We designed and built a <span className={classes.negrilla}>Serverless Architecture</span> for an online store with a strong focus on <span className={classes.negrilla}>Cost Optimization  </span>and  <span className={classes.negrilla}>fast prototyping.</span> The solution includes a combination of <span className={classes.negrilla}>WAF, Api Gateway, Lambda Functions</span> and <span className={classes.negrilla}>DynamoDB</span> components designed to work on a  <span className={classes.negrilla}>Multi Account Environment.</span> The infrastructure was created by using <span className={classes.negrilla}>Terraform</span>  </p>
                <hr className={classes['bh-divider']}/> 
                <h2 className={classes['project-subtitle']}> Architecture</h2>
                <p>The following image describes the architecture we have implemented: </p>
                <img src={eco_arquitecture} alt="AZFA architecture" className={classes['img-tech']}/>
                <hr className={classes['bh-divider']}/> 
                <h2 className={classes['project-subtitle']}>DynamoDB Table and MVC Framework</h2>
                <p>We provide a <span className={classes.negrilla}>python framework</span> for mapping object oriented data structures to  <span className={classes.negrilla}>NOSQL DynamoDB </span>  format. We follow <span className={classes.negrilla}>devops best practices</span> for <span className={classes.negrilla}>multi-account continuous integration and deployments.</span> </p>
                <img src={eco_aws} alt="AZFA website" className={`${classes['img-tech2']} ${classes['mb-1']}`}/>
            </section>
            </main>
            <Footer/>
        </div>
    );
;}

export default Ecorolling;