import classes from '../../../css/styles.module.scss';
import Footer from '../../../Footer/Footer';
import { Helmet } from 'react-helmet';

const Contacto = () => {
    return (
        <div>
			<Helmet><title>Backend Helpers Limited | contact </title></Helmet>
            <main className={classes['main-content']}>
    	    <section id="contact-us" className={`${classes.container} ${classes['contact-us']}`}>
    		<div  className={classes['contact-left']}>
				<h1 className={classes['contact-title']}>
					CONTACT US <br/>
					<span className={classes['bg-title-contact']}>CONTACT</span><br/>
				</h1>
            </div>
			<div  className={classes['contact-right']}>
				 <h2>@</h2>
				 <h3>Send us an email to
					info@backendhelpers.co </h3>
			</div>
    	    </section>
            </main>

			<Footer/>
        </div>
    );
};

export default Contacto;