import azfa_arquitecture from '../../../img/AZFA/azfa_arquitecture.png'
import azfa_cluster from '../../../img/AZFA/azfa_cluster.png'
import classes from '../../../css/styles.module.scss';
import Footer from '../../../Footer/Footer';
import { Helmet } from 'react-helmet';
const Azfa = () => {
    return (
        <div>
            <Helmet><title>Backend Helpers Limited | azfa </title></Helmet>
            <main className={classes['main-content']}>
            <section id="" className={classes['project-content']}>
                <h1 className={classes['project-title']}>AWS Infrastructure <br /> for the Americas Free Trade Zone association</h1>
                <p className={classes['mb-1']}> Our company is responsible for <span className={classes.negrilla}>operating a high profile </span>website on behalf of the Americas Free Trade association: A <span className={classes.negrilla}> multilateral organization</span> that groups free trading regions across all america. 
                <a href="https://www.asociacionzonasfrancas.org/" className="" target="_blank"> asociacionzonasfrancas.org </a> </p>
                <hr className={classes['bh-divider']}/> 
                <h2 className={classes['project-subtitle']}> Architecture</h2>
                <p>The following image describes the architecture we have implemented: </p>
                <img src={azfa_arquitecture} alt="AZFA architecture" className={classes['img-tech']}/>
                <div className={classes['container-azfa']}>
                <p>We use <span className={classes.negrilla}>Hashicorp terraform</span> to create a infrastructure as code stack that includes the following components: </p>
                <ul className={classes.lyst}>
                    <li>Custom VPC that Isolates all resources</li>
                    <li>WAF Firewall to protect the website against common attacks</li>
                    <li>Load balancer that distribute workload across different servers</li>
                    <li>Cloud front distribution backend by a s3 bucket to manage static assets</li>
                    <li>EC2 instances for running python web server applications.</li>
                    <li>RDS database for postgresql with read replica enable</li>
                </ul>
                </div>
                <hr className={classes['bh-divider']}/> 
                <h2 className={classes['project-subtitle2']}> CONTINUOUS INTEGRATION, AUTOMATION AND DEVOPS PRACTICES</h2>
                <p>We follow <span className={classes.negrilla}>devops best practices</span> for <span className={classes.negrilla}>multi-account continuous integration and deployments.</span> </p>
                <img src={azfa_cluster} alt="AZFA website" className={`${classes['img-tech']} ${classes['mb-1']}`}/>
            </section>
            </main>
            <Footer/>
        </div>
    );
};

export default Azfa;