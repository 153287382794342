import Footer from '../../../Footer/Footer';
import React from 'react';
import classes from '../../../css/styles.module.scss';
import { Link } from 'react-router-dom';
import {useTranslation, withTranslation,  Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Us = () => {

    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

   

    return (
        <div>
            <Helmet>
            <title>Backend Helpers Limited | us </title>
            </Helmet>
         <div className={classes.languageButtons}>
            <Link to="/es"><button type="button"  onClick={() => changeLanguage('es')} className={classes.button_lang}>es</button></Link>
            </div>
            <main className={classes['main-content']}>
      
            <section id="index" className={`${classes.container} ${classes.index}`}>
         
                <div className={classes['contact-left']}>
                    <h1 className={classes['rw-sentence']}>
                        WE AUTOMATE YOUR <br />
                        <span class={classes['bg-title']}>INFRASTRUCTURE</span><br />
                         AWS - AZURE
                    </h1>
                </div>
                <div className={`${classes['col-6']} ${classes['col-s-12']} ${classes['padding-1']} `}>
                    <h2 className={classes['text-left']}>WHO WE ARE  </h2>
                    <p className={classes.who}>We are a <span className={classes.negrilla}>software factory</span> that relies on <span className={classes.negrilla}>automation</span> for providing cloud solutions. We focus on  <span className={classes.negrilla}>security, reliability</span>   and  <span className={classes.negrilla}>cost optimization</span> </p>
                </div>
            </section>
            </main>
            
         
            <Footer/>
        </div>
        
    );
};


export default Us;