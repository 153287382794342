import Us from '../Pages/en/Us/Us';
import UsE from '../Pages/es/Us/Us';
import './App.scss';
import React from 'react';
import { BrowserRouter ,Navigate,Route, Routes } from 'react-router-dom';
import SuccessStories from '../Pages/en/Success-stories/Success-stories';
import Header from '../Header/Header';
import Azfa from '../Pages/en/Azfa/AZFA';
import EcoRolling  from '../Pages/en/EcoRolling/Ecorolling';
import Contact from '../Pages/en/Contact/Contact';
import AzfaE from '../Pages/es/Azfa/AZFA';
import EcoRollingE from '../Pages/es/EcoRolling/Ecorolling';
import ContactE from '../Pages/es/Contact/Contact';
import SuccessStoriese from '../Pages/es/Success-stories/Success-stories';


function App() {

  const currentLanguage = "es"; 
  return (
    <div>
      <BrowserRouter>
     <Header/>
     
   <Routes>
   <Route path="/" element={<Navigate to = "/en"/>  }/>
    <Route path="/en" element={<Us/>}/>
    <Route path="/en/success-stories" element={<SuccessStories/>}/>
    <Route path="/en/success-stories/aws-azfa" element={<Azfa/>}/>
    <Route path="/en/success-stories/ecorolling" element={<EcoRolling/>}/>
    <Route path="/en/contact" element={<Contact/>}/>

      {currentLanguage === 'es' && (
          <>
            <Route path="/es" element={<UsE />} />
            <Route path="/es/historias-exito" element={<SuccessStoriese />} />
            <Route path="/es/historias-exito/aws-azfa" element={<AzfaE />} />
            <Route path="/es/historias-exito/ecorolling" element={<EcoRollingE />} />
            <Route path="/es/contacto" element={<ContactE />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
