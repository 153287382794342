import linkedin from "../img/Footer/linkedin.png";
import whatssap from "../img/Footer/whatf.png";
import classes from "../css/styles.module.scss"


const Footer = () => {

    return (
        <div className={classes.footer}>
            <div className={classes['container-f']}>
            <div className="flex-col">
                <h2 className="">CONTACT US ON SOCIAL NETWORKS</h2>
            <div className={classes['social-media']}>
                    <a href="https://www.linkedin.com/in/mfran/" target="_blank" >
                        <img src={linkedin} alt="linkedin" className={classes['img-sm']}/>
                    </a>
                    <a href="https://wa.me/353896000322?text=Welcome%20to%20BackendHelpers!%0A%0AWe%20are%20your%20software%20factory%20specialized%20in%20creating%20automated%20applications%20for%20AWS." target="_blank" >
                        <img src={whatssap} alt="linkedin" className={classes['img-sm2']}/>
                    </a>
            </div>
	        <div className={classes['container-f']}>
	            <p className={classes.credits}>® 2022 Backend Helpers Limited. All rights reserved.</p>
	        </div> 
            </div>
            </div>
        </div>
    );
};

export default Footer;